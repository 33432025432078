:root {
  --color-text: rgb(0, 200, 0);
  --color-background: black;
  /*
  --color-text: white;
  --color-background: blue;
  */
}

html {
  font-size: 25px;
}

@media (min-width: 0px) {
  html {
    font-size: 1.7rem;
  }
}

@media (min-width: 700px) {
  html {
    font-size: 1.0rem;
  }
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  padding: .25em;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Consolas', monospace;
  margin: 0;
}

#terminal {
  width: 100%;
  flex-grow: 1;
  /*
  display: flex;
  flex-direction: column;
  */
  align-items: flex-start;
}

#terminal .inputRow {
  display: flex;
  align-items: flex-start; /* Align items to the top */
}

#terminal .inputRow span {
  align-self: flex-start; /* Anchor the span at the top */
}

#terminal .inputCommand {
  flex-grow: 1;
  border: none;
  outline: none;
  background: var(--color-background);
  caret-color: var(--color-text);
  color: var(--color-text);
  width: calc(100% - 4.5ch);
  font-family: 'Consolas', monospace;
  font-size: 1rem;
  color: white;

  resize: none;
  overflow: hidden;
  height: .8rem;
}

#terminal .inputTextQuestion {
  flex-grow: 1;
  border: none;
  outline: none;
  background: var(--color-background);
  caret-color: var(--color-text);
  color: var(--color-text);
  width: calc(100% - 1.5ch);
  font-family: 'Consolas', monospace;
  font-size: 1rem;
  color: white;

  resize: none;
  overflow: hidden;
  height: 0.8rem;
}

#terminal .inputLoading {
  border: 1px solid;
  outline: none;
  background: var(--color-background);
  caret-color: var(--color-background);
  color: var(--color-background);
  width: 25%;

  font-size: 1rem;
  height: .8rem;

  /*
  font-size: 30px;
  height: 1px;
  */
  resize: none;
  overflow: hidden;
}

#terminal .textRow {
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}
